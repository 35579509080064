import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

export default () => (
  <Layout>
    <SEO title="About" keywords={[`about`, `legal`]} />

    <div className="mx-auto leading-normal">
      <p className="leading-normal">
        Viewsource Limited provides Software Development, Project Mangement, and Technical Consulting services.
      </p>
    </div>
    <div className="mx-auto leading-normal pt-4">
      <p className="font-bold leading-loose">Viewsource Limited</p>
      <p className="leading-normal">2301 Bayfield Building</p>
      <p className="leading-normal">99 Hennessy Road</p>
      <p className="leading-normal">Wanchai, Hong Kong</p>
      <p className="leading-normal">contact@viewsource.com.hk</p>
    </div>
  </Layout>
)
